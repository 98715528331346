.container {
  display: flex;
  height: 40px;
  max-width: 89%;
  margin-left: auto;
  @media (max-width: 1400px) {
    margin-left: 35px;
    max-width: 100%;
  }
}

.searchSelect {
  min-width: 180px;
  width: calc(max(200px, 20%));
  flex-grow: 1;

  & .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #8e3fbe;
    border-width: 2px;
    border-right: 2px solid #8e3fbe;
    &::after {
      content: none;
    }
  }

  & :global(.MuiOutlinedInput-input) {
    padding: 8px 18px;
  }

  & :global(.MuiOutlinedInput-notchedOutline) {
    height: 45px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    border-width: 1px;
    border-color: #ccc;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      right: 5px;
      display: block;
      width: 1px;
      height: calc(100% - 8px);
      border-radius: 10px;
      background-color: #ccc;
      color: #ccc;
    }
  }
}

.searchBlock {
  width: 100%;
}