.rightSideItemAnchor {
  border-top: 1px solid #ebebeb;
  display: block;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &.active + a {
    border-top-color: transparent;
  }
  &.active {
    margin: 0 -20px;
    width: calc(100% + 40px);
    background-color: #fff;
    border: 2px solid #8e3fbe;
    border-radius: 8px;
    padding: 0 18px;
    &.last {
      margin-bottom: 0;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  user-select: none;
}

.deliveryWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
}

.outletName {
  margin-bottom: 4px;
}

.totalQty {
  & > * {
    color: #666;
  }
}

.fundsPositive {
  margin: 0;
  color: #8F9E1C;

}