.favoritesLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 11px;
  &:hover {
    text-decoration: none;
  }
}

.icon {
  & > svg {
    &:hover,
    &:active {
      color: #e91e63;
    }
  }
}

.label {
  &:hover {
    color: #8e3fbe;
  }
  @media (max-width: 1400px) {
    display: none;
  }
}
