.container {
  display: flex;
  flex-direction: column;
}

.card {
  width: 100%;
  & > div {
    height: auto;
    padding: 4px 24px 0;
    margin-top: 1px;
    @media (max-width: 720px) {
      padding: 0 15px 0;
    }
  }
}

.params {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 0;
  &:last-of-type {
    padding-bottom: 0;
  }
}

.qtyContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  & :global(.MuiBox-root) {
    height: 40px;
  }
}

.paramLabel {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.sumLabel {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

.splitedPrice {
  & > * {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #000;
  }
}

.sumPrice {
  & > * {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #000;
  }
}

.disabled {
  cursor: not-allowed;
  color: #5c5c5c;
}

.orderItemRemove {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: #858585;
}