.container {
  max-width: 1136px;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    gap: 100px;
    max-width: 1096px;
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 720px) {
    gap: 8px;
    padding-bottom: 50px;
    width: 100%;
  }
}

.orderItemsGrid {
  display: flex;
  flex-direction: column;
}

div.containerTopCard {
  margin-bottom: 16px;
  padding: 24px 33px;
  display: flex;
  gap: 32px;
  @media (max-width: 720px) {
    margin-bottom: 8px;
    flex-wrap: wrap;
    gap: 16px;
    padding: 24px 14px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  @media (max-width: 720px) {
    flex: 0 0 calc(50% - 8px);
  }
  &:first-of-type {
    @media (max-width: 720px) {
      height: 40px;
    }
  }
}

.label {
  font-size: 16px;
  font-weight: 400;
  color: #535353;
  line-height: 16px;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.value {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  &.newOrderStatus {
    background-color: #f1e8f7;
    font-weight: 400;
    color: #262626;
    padding: 0 8px;
    max-width: fit-content;
    border-radius: 4px;
    @media (max-width: 720px) {
      padding: 3px 8px;
    }
  }
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.title {
  margin: 0 0 16px;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

div.orderCard {
  padding: 24px 32px;
  @media (max-width: 720px) {
    padding: 14px 16px 16px;
  }
}

.desktopButton {
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  @media (max-width: 720px) {
    display: none;
  }
}

button.mobileButton {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  &:global(.MuiButtonBase-root) {
    padding: 13px 24px;
  }
  &:first-of-type {
    margin: 38px 0 24px;
    padding: 11px 24px;
  }
  @media (min-width: 720px) {
    display: none;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.orderLabelProduct {
  margin: 0 0 18px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 24px;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin: 0;
  }
}

.orderId {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 16px;
  @media (max-width: 720px) {
    margin-bottom: 8px;
  }
}

div.outlineMargin {
  @media (max-width: 720px) {
    margin-bottom: 16px;
  }
  @media (min-width: 1920px) {
    display: none;
  }
}

.link {
  @media (max-width: 720px) {
    display: none;
  }
}
