.link {
  padding: 10px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:active {
    background-color: #f1e8f7;

    & .menu-cart-icon {
      color: 8e3fbe;
    }

    & > p {
      color: #8e3fbe;
    }
  }

  &:focus, &:hover {
    text-decoration: none;

    & .menu-cart-icon > svg {
      color: #8e3fbe;
    }

    & > p {
      color: #8e3fbe;
    }
  }

  .mobile-menu-top-links {
    flex-direction: column;
  }

  &:last-of-type {
    & > div > p {
      &:focus, &:hover {
        color: #8e3fbe;
      }
    }
  }
  @media (max-width: 960px) {
    padding: 4px 8px;
  }
  @media (max-width: 720px) {
    display: block;
  }
}



.container {
  display: flex;
  align-items: center;
  flex: 1;
}