.continueShoppingBtn {
  line-height: 1.4;
  @media (max-width: 720px) {
    padding: 13px 22px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    height: auto;
  }
}

.buyBtn {
  @media (max-width: 720px) {
    padding: 10px 24px 10px 7px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  }
}
