.wrapper {
  letter-spacing: 0.03em;
  color: #555;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 24px 10px 16px ;
  height: 32px;
  @media (max-width: 1000px) {
    display: none;
  }
}

.layoutIcons {
  display: flex;
}

.priceActions {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: 24px;

  & > button {
    border: 1px solid #000;
    border-radius: 2px;
    padding: 6px 10px 6px 8px;

    &:hover {
      fill: #8e3fbe;
      background: #f1e8f7;
    }

    &.active {
      border-color: #8e3fbe;
      fill: #8e3fbe;
      background: #f1e8f7;

      & > span {
        font-weight: 500;
      }
    }
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: #333;
}

.deliveryContainer {
  display: flex;
  align-items: center;
  margin-right: auto;
  @media (max-width: 960px) {
    display: none;
  }
}

.deliveryLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #333;
}

.chip {
  padding-right: 16px;
  & > span {
    padding: 0 4px 0 8px;
  }
}