.cardPaper {
  width: 48.5%;

  &.smallCards {
    width: 32%;
    @media (max-width: 850px) {
      width: 48.5%;
    }
    @media (max-width: 720px) {
      width: 100%;
    }
  }

  @media (max-width: 720px) {
    width: 100%;
  }
}

.cardLink {
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.cardLoyalty {
  position: relative;
}

.cardLoyaltyImage {
  width: 100%;
  height: 112px;
  object-fit: cover;
  @media (max-width: 720px) {
    border-radius: 8px 8px 0 0;
  }
}

.cardLoyaltyTooltip {
  position: absolute;
  top: 8px;
  right: 16px;
}

.tooltipClass {
  max-width: 500px;
  min-width: 300px;
}

.cardLoyaltyTooltipIcon {
  background-color: rgba(0, 0, 0, 0.44);
  padding: 7px;
  border-radius: 50%;
  color: #fff;
}

.cardLoyaltyContent {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 56px;

  &.balanceCardLoyaltyContent {
    flex-direction: column;
    padding: 24px;
    height: 100%;
  }
}

.percentLevel {
  color: #333;
  margin: 0;
}

.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.balanceCardLoyaltyName {
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    min-height: 48px;
  }
}

.currentDiscountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

a.link {
  width: 100%;
  margin-top: 24px;

  & > button {
    padding: 6px 24px !important;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
}

.description {
  margin: 8px 0 16px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #535353;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 42px;
  @media (max-width: 720px) {
    min-height: auto;
  }
}

.currentDiscountMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #000;
}

.percent {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000;
}

.date {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  width: 100%;
  display: block;
  color: #262626;
  margin-top: 4px;
}
