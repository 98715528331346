.container {
  flex: 0 0 auto;
  width: 100%;
  height: 126px;
  z-index: 1300;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  @media (max-width: 960px) {
    height: fit-content;
    position: fixed;
    top: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  @media (max-width: 960px) {
    align-items: flex-start;
  }
}

.mainContent {
  @media (max-width: 960px) {
    flex-direction: column;
    text-align: left;
  }
}