.card {
  width: 100%;
  margin-top: 16px;
  & > div {
    height: auto;
    padding: 4px 24px 0;
    margin-top: 1px;
    @media (max-width: 720px) {
      padding: 0px 15px 0;
    }
  }
}

.miniDetailsWrapper {
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  &.noOffers {
    width: 100vw;
  }
}

div.offerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: 100%;
  & > div {
    &:first-of-type {
      margin: 16px 0 0 0;
    }
  }
  @media (max-width: 720px) {
    padding: 0 16px 16px;
  }
}
