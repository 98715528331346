.container {
  display: flex;
  flex-direction: column;
}

div.outlineMargin {
  transform: translateY(-24px);
  margin-top: 24px;
  margin-left: -38px;
  margin-right: -32px;
  margin-bottom: -8px;
  @media (max-width: 1160px) {
    margin-left: -18px;
    margin-right: -16px;
  }
}

.orderCardActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  @media (max-width: 720px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.outletName {
  margin: 0 0 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.deliveryCount {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.cartAction {
  padding: 0;
}

button.cancelBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-right: 20px;
}

.buyBtn {
  padding: 13px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}