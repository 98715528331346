.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  padding: 4px 0;

  &.value {
    color: #333;
  }
}

span.heading {
  min-height: 14px;

  & > * {
    border-bottom: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #333;
  }
}

.details {
  padding: 0;
}

div.summary {
  height: 24px;

  & > * {
    margin: 0;
  }
}
