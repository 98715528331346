.expandedMobileContainer {
  display: flex;
  flex-direction: column;
  border: 2px solid #8e3fbe;
  border-top: none;
  width: calc(100% + 38px);
  margin-left: -19px;
  padding: 0 19px 8px;
  border-radius: 0 0 8px 8px;
}

.expandedMobileRow {
  padding: 9px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.colorCircle {
  height: 8px;
  width: 8px;
}

.statusWrapper {
  display: flex;
  align-items: center;
  color: #535353;
}

.dateValue {
  margin-top: 3px;
}