.container {
  background: #fff;
  width: 100%;
  box-shadow: 2px -2px 2px #cbcbcb;
  position: relative;
  z-index: 1201;
  @media (max-width: 1100px) {
    box-shadow: none;
    border-top: 1px solid #cbcbcb
  }
}

.wrapper {
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  margin: 0 auto;
  max-width: 1124px;
}
