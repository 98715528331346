$widthLeft: 58%;
$widthLeftMobile: 55%;
$widthRight: 42%;
$widthRightMobile: 45%;

.transitDeliveryTypeContainer {
  display: flex;
  width: 100%;
  color: #8f8f8f;
}

.transitContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.deliveryTimeInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.deliveryTimePreOrder {
    justify-content: initial;
    gap: 8px;
  }
}

.deliveryTime {
  white-space: nowrap;
}

.cardInnerItemsLeft {
  text-align: right;
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 16px 14px 8px;
  width: $widthRight;
  background-color: #f9f9f9;
  @media (max-width: 720px) {
    width: $widthRightMobile;
  }
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
}

.deliveryTimeContainer {
  background-color: #fff;
  display: flex;
}

.deliveryTimeWrapper {
  display: flex;
  width: $widthLeft;
  padding: 16px 14px 8px;
  justify-content: space-between;
  flex-direction: column;
  gap: 2px;
  margin: 0;
  position: relative;
  @media (max-width: 720px) {
    width: $widthLeftMobile;
  }
  .deliveryTimeContent {
    padding: 0 14px 8px;
  }
}

.deliveryTimeWrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 14px;
  right: 14px;
  height: 1px;
  background-color: #efefef;
}

.deliveryDate {
  font-size: 14px;
  line-height: 14px;
  color: #5c5c5c;
  font-weight: 500;
}

.deliveryLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #5c5c5c;
}

.priceWrapper {
  padding: 16px 16px 8px;
  background-color: #f9f9f9;
  display: flex;
  align-items: flex-start;
  width: $widthRight;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 16px 16px 6px;
    width: $widthRightMobile;
  }
}

.priceContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 720px) {
    gap: 0;
  }
}

.splitedWithoutDiscount {
  line-height: 12px;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
  & > * {

    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: line-through;
  }
}

.splitedPriceDiscount {
  line-height: 12px;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
  & > * {

    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #0a0a0a;
  }
}

.splitedPreOrder {
  color: #666;
  text-align: right;
  line-height: 12px;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
  & > * {

    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-decoration: none;
  }
}

.priceContainer {
  display: flex;
}

.availableCountContainer {
  display: flex;
  align-items: center;
  width: $widthLeft;
  padding: 0 16px;
  justify-content: space-between;
  @media (max-width: 720px) {
    width: $widthLeftMobile;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #5c5c5c;
}

.value {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #333;
}

.sumContainer {
  padding: 3px 16px 4px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  width: $widthRight;
  justify-content: space-between;
  @media (max-width: 720px) {
    width: $widthRightMobile;
    padding: 0 16px 4px;
  }
}

.sumLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.sumPriceLabel {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.totalPriceContainer {
  display: flex;
  align-items: center;
}

.quantityContainer {
  width: $widthLeft;
  padding: 0 16px 16px;
  margin-top: 4px;
  & :global(button.button-decrease) {
    @media (max-width: 720px) {
      margin-right: 3px;
    }
  }
  & :global(button.button-increase) {
    @media (max-width: 720px) {
      margin-left: 3px;
    }
  }
  @media (max-width: 720px) {
    width: $widthLeftMobile;
    margin-top: 0;
    padding-top: 9px;
  }
}

.quantity {
  & > * > * {
    &:global(.MuiAutocomplete-inputRoot) {
      width: 125px;
      height: 40px;
    }
  }
}

.sumButton {
  padding: 8px 16px 16px;
  border-radius: 0 0 8px;
  width: $widthRight;
  background-color: #f9f9f9;
  @media (max-width: 720px) {
    padding: 9px 16px 16px;
    width: $widthRightMobile;
  }
}

.btn {
  padding: 10px 26px;
  @media (max-width: 720px) {
    padding: 12px 26px;
  }
}

.preOrderText {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  margin-bottom: 6px;
}