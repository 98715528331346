.container {
  margin: 0 auto;
  width: 100%;
  max-width: 416px;
}

.title {
  margin-bottom: 8px;
  user-select: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 8px;
  user-select: none;
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
}

.content {
  display: flex;
  flex-direction: column;
}

.commentTitle {
  padding-bottom: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
}

.footer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;
  user-select: none;
}

.totalInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checkoutOrdersSummaryCard {
  padding: 0 16px 16px;
}

.commentWrapper {
  margin-top: 16px;
}

button.cancelBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-right: 20px;
}

.submitBtn {
  padding: 13px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}

.actions {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}