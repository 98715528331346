.footerMobile {
  z-index: 1203;
  position: fixed;
  height: 54px;
  width: 100%;
  display: flex;
  top: unset;
  bottom: 0;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px #cbcbcb;
  @media (min-width: 960px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.item {
  display: flex;
  flex: 1;
  justify-content: center;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  & .label {
    display: none;
  }
  &.active {
    color: #8e3fbe;
    & .label {
      display: inline-block;
    }
  }
}