.container {
  display: flex;
  width: 100%;
}

.productImage {
  height: 40px;
  width: 40px;
  border-radius: 4px;
}

.productName {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: clamp(200px, calc(100vw - 200px), 300px);
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.attributesContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.attrValue {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #333;
}

.productInfo {
  display: flex;
}

.attributeContainer {
  display: flex;
  align-items: center;
}

.colorCircle {
  display: flex;
  margin-right: 0;
}

.productContainer {
  padding-left: 10px;
}

.productInfoCell {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.productInfo {
  display: flex;
}

.isOutOfStockContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashIconContainer {
  margin-left: auto;
}

.trashIcon {
  padding: 0;
}
