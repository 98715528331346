.cardFooterTotal {
  margin-left: auto;
  text-align: right;
}

.totalPrice {
  color: #000;

  & > span > * {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    &:last-of-type {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }

}