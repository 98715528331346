.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  color: #646464;
}
.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #646464;
}

.splicedPrice {
  & > * {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #646464;
  }
}

.quantityLabelContainer,
.priceLabelContainer {
  margin-bottom: 4px;
  height: 18px;
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
}