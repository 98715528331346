.tableItem {
  border-bottom: 1px solid #ebebeb;
  padding: 16px 0;

  &:last-of-type {
    border-bottom: 1px solid #ccc;
  }
}

.outlineMargin {
  border: 1px solid #8e3fbe;
  margin: 0 -18px;
  padding:16px 20px 0 20px;
  border-radius: 8px;
  &:last-of-type {
    border-bottom: 1px solid #8e3fbe;
  }
}

.summary {
  & > * {
    margin: 0;
  }

  &:first-of-type {
    height: 40px;
  }
}
