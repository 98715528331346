.title {
  margin-bottom: 8px;
  text-align: center;
  @media (max-width: 720px) {
    text-align: left;
    padding: 0 16px;
    margin-bottom: 14px;
  }
}

.miniCartCard {
  margin-bottom: 40px;
  @media (max-width: 720px) {
    margin-bottom: 48px;
    margin-top: 23px !important;
  }
}

.miniCartCardHeader {
  position: relative;
  user-select: none;
}

.miniCartCardHeaderTimer {
  position: absolute;
  right: 16px;
  bottom: 4px;
  & > p > span {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
}

.miniCartCardPaper {
  padding: 16px;
  border: 1px solid #ebebeb;
  @media (max-width: 720px) {
    margin-top: 0;
    margin-bottom: 30px;
    padding: 8px;
    border: 0;
    box-shadow: none;
  }
}

.miniCartCardSummary {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  user-select: none;
  padding: 0 16px;
  @media (max-width: 720px) {
    padding: 0;
    margin-top: 13px;
  }
}

.summaryInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  @media (max-width: 720px) {
    gap: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  padding: 0 0 16px;
  border-radius: 8px;
  @media (max-width: 720px) {
    padding: 0 16px;
    border: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #666;
}

.labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 16px 10px;
  user-select: none;
  @media (max-width: 720px) {
    display: none;
  }
}

.totalSum {
  & > * {
    font-weight: 700;
    color: #000;
    @media (max-width: 720px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.totalQtyValue {
  line-height: 14px;
  & > * {
    color: #666;
    font-size: 14px;
    font-weight: 400;
  }
}

.totalSumLabel {
  color: #000;
}