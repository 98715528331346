.container {
  display: flex;
  gap: 24px;
  width: 100%;
  @media (max-width: 1900px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  @media (max-width: 720px) {
    padding: 0 16px;
    gap: 16px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  max-width: 1136px;
  margin-left: 52px;
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    max-width: 100%;
    padding-right: 20px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 720px) {
    margin: 0;
    gap: 8px;
    padding-bottom: 50px;
    width: 100%;
    padding-right: 0;
  }
}

.title,
.titleTablet {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  color: #000;
  margin: 0;
  @media (max-width: 1900px) {
    display: none;
  }
}


