.hours {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #333;
  margin: 0;
}

.weekends {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}