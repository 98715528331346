div.tableContainer {
  padding: 24px 32px;
  @media (max-width: 720px) {
    width: 100%;
    overflow: hidden;
  }
}

div.tableContainerDashboard {
  padding-bottom: 0;
  margin-bottom: 0;
}

.titleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding-bottom: 16px;
  }
}

div.tableContentWrapper {
  width: 100%;
  padding: 16px 32px 24px;
  @media (max-width: 720px) {
    padding: 16px;
  }
}

.filterContainer {
  @media (max-width: 720px) {
    display: none;
  }
}

.tableToolbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 720px) {
    display: none;
  }
}

button.btn {
  padding: 0;
}

.label {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #333;
}

.actionContainer {
  display: flex;
  align-items: center;
  & > div {
    margin: 0;
  }
  & > button {
    @media (max-width: 720px) {
      display: none;
    }
  }
  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.actionButton {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 9px 10px;
  border: 1px solid #535353;
  border-radius: 2px;
  color: #535353;
  background-color: #fff;
  &:last-of-type {
    margin-right: 32px;
    margin-left: 8px;
  }
  &.activeActionButton {
    border-color: #8e3fbe;
    color: #242225;
    background-color: #f1e8f7;
  }
  &:hover {
    background-color: #f1e8f7;
  }
}
