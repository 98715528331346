.expired {
  background: transparent;
}

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 16px;
}

.transitGroup {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: auto;
}

.subTotalContainer {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.cartGroupCard {
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

div.notEnoughBalanceAlert {
  width: calc(100% + 32px);
  margin-left: -16px;
}