.container {
  background: #fff;
  max-width: 1124px;
  width: 100%;
  margin: 0 auto;
}

.wrapper {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}

.socialLinks {
  display: flex;
  align-items: center;
}