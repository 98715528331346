.container {
  display: flex;
}

.comment {
  margin: 20px 0 15px 15px;
  @media (max-width: 720px) {
    margin: 0;
  }
}

.commentTitle {
  padding-bottom: 3px;
}

.commentContainer {
  display: flex;
  align-items: center;
}

.tableItem {
  padding: 17px 0;
  display: flex;
  align-items: center;
}

.wrapper {
  @media (max-width: 720px) {
    display: none;
  }
}

.mobContainer {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 720px) {
    display: none;
  }
}

.quantityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.totalSumContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.totalSumLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
  height: 16px;
  @media (min-width: 720px) {
    display: none;
  }
}

.discountContainer {
  display: flex;
  align-items: center;
}

.discountWrapper {
  display: flex;
  flex-direction: column;
}

.totalSumWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0 14px;
  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-end;
    padding: 16px 0 0;
  }
}

.deliveryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 14px;
}

.deliveryLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
}

.deliveryPrice {
  > * {
    color: #000;
    font-weight: 700;
    line-height: 21px;
    @media (max-width: 720px) {
      line-height: 14px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &:last-of-type {
    font-size: 14px;
  }
}

.sumItemsLabel {
  max-width: 438px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  &.accountSumItemsLabel {
    max-width: 522px;
  }
}

.quantityCountLabel {
  max-width: 208px;
  display: flex;
  justify-content: center;
  flex: 1;
  margin-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  &.accountQuantityCountLabel {
    max-width: 140px;
  }
}

.inPackageCountLabel {
  max-width: 84px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  &.accountInPackageCountLabel {
    max-width: 140px;
  }
}

.removeItem {
  max-width: 56px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 16px;
  &.accountRemoveItem {
    display: none;
  }
}

.discountPrice {
  max-width: 90px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 16px;
}

.orderItemSubtotal {
  max-width: 116px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: flex-end;
  line-height: 14px;
  & > * {
    font-weight: 400;
  }
}

.discountValue {
  height: 14px;
  & > * {
    font-weight: 400;
  }
}

.mobileWrapper {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px 0 8px;
  align-items: center;
}

.qtyContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
}

.discountContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
}

.summaryLabel {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
  @media (max-width: 720px) {
    height: 16px;
  }
}

.totalQtyValue {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
  @media (max-width: 720px) {
    height: 16px;
  }
}

.discount {
  @media (max-width: 720px) {
    height: 16px;
  }
  & > * {
    color: #000;
    @media (max-width: 720px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.sum {
  @media (max-width: 720px) {
    height: 24px;
  }
  & > * {
    font-weight: 700;
    @media (max-width: 720px) {
      &:first-of-type {
        font-size: 20px;
        line-height: 24px;
      }
      &:last-of-type {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
