.cardHeader {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #ebebeb;
  border-radius: 8px 8px 0 0;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.cardHeaderTitle {
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardHeaderWrapper {
  display: flex;
  align-items: center;
}

.deliveryType {
  color: #009bdd;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.discount {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 20px;
  padding: 5px 6px;
  background-color: #cddc39;
  color: #000;
  margin-right: auto;
}

.fireBordered {
  border: 1px solid #d770ad;
  border-radius: 4px;
  background-color: #fff;
}

.goodsComingBordered {
  border: 1px solid #ffb200;
  border-radius: 4px;
  background-color: #fff;
}

.cardHeaderOrderType {
  font-size: 14px;
  padding-left: 10px;
}