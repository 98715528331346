.titleTablet {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  color: #000;
  margin: 0;
  @media (max-width: 1900px) {
    display: none;
  }
}

.titleTablet {
  display: block;
  @media (min-width: 1900px) {
    display: none;
  }
  @media (max-width: 1500px) {
    margin-left: 16px;
  }
  @media (max-width: 720px) {
    margin: 0;
  }
}

.balanceContentTablet {
  display: flex;
  justify-content: space-between;
  @media (min-width: 1900px) {
    display: block;
  }
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
}

div.balanceContainer {
  padding: 8px 32px 24px;
}

.balanceContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  padding-top: 16px;
  flex-wrap: wrap;
  @media (max-width: 1900px) {
    border-bottom: none;
    flex: 1;
    align-items: flex-start;
  }
  @media (max-width: 720px) {
    border-bottom: 1px solid #ccc;
  }
}

.balanceTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #252525;
}

.totalBalanceContainer {
  padding-top: 16px;
  @media (max-width: 1900px) {
    border-left: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 48px;
    margin-left: 48px;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0;
    border-left: none;
    padding-top: 16px;
  }
}

.itemTotalBalance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    padding-bottom: 8px;
  }
  &:last-of-type {
    padding-top: 2px;
  }
}

.balanceItemTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #252525;
}

.balanceItemSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #666;
}

span.splitedPrice {
  & > * {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #666;
  }
}

span.splitedPriceItem {
  & > * {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    line-height: 23px;
  }
}

.tableToolbarContainer {
  @media (min-width: 720px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  min-width: 440px;
  margin-top: 110px;
  height: fit-content;
  @media (max-width: 1900px) {
    margin: 0;
    max-width: 1136px;
    margin-left: 52px;
  }
  @media (max-width: 1500px) {
    margin-left: 16px;
    max-width: 100%;
    padding-right: 4px;
  }
  @media (max-width: 720px) {
    margin: 0;
    background-color: #fff;
    min-width: calc(100% + 32px);
    margin-left: -16px;
    padding: 0 16px;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.16);
  }
}

.btn {
  padding: 10px 26px;
}

.creditContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}