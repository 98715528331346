.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 1140px;
  @media (max-width: 1200px) {
    min-width: 100%;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.content {
  display: flex;
  flex: 1;
  &.contentExpanded {
    @media (max-width: 720px) {
      padding-bottom: 0;
    }
  }
  @media (max-width: 920px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.contentItem {
  border-right: 1px solid #c1c1c1;
  margin-right: 24px;
  padding-right: 24px;
  display: flex;
  flex: 1;
  width: 330px;

  &:last-of-type {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    flex-direction: column;
  }

  &:first-of-type {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    @media (max-width: 720px) {
      flex-direction: column;
    }
  }
  @media (max-width: 920px) {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    width: 100%;
    &:not(:last-of-type) {
      border-bottom: 1px solid #c1c1c1;
      padding-bottom: 8px;
      margin-bottom: 12px;
    }
    &:first-of-type {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 24px;
    }
  }
}

.factDepositContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.currentWeekContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 720px) {
    margin-left: 32px;
  }
}

.titleItem {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: #000;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.factDepositItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    margin-bottom: 8px;
  }
  @media (max-width: 720px) {
    margin-bottom: 24px;
  }
}

.factDepositLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #535353;
}

.factDepositValue {
  & > * {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #535353;
  }
}

.name {
  margin-bottom: 4px;
}

.fixedDiscount {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #535353;
  @media (max-width: 720px) {
    display: none;
  }
}

.discountFixedEstimated {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #535353;
}

.item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 720px) {
    margin: 0;
  }
}

.discountMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #000;
  @media (max-width: 720px) {
    font-weight: 400;
    color: #535353;
  }
}

.currentDepositLabel {
  color: #535353;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.discountValue {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}

.currentDepositValue {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #535353;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.spacing {
  margin-bottom: 2px;
}

.depositForDiscount {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
  @media (max-width: 720px) {
    line-height: 14px;
    font-size: 14px;
    padding: 5px 0;
  }
}

.estimatedDiscountValue {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #7c7c7c;
}

.estimatedDiscountLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #535353;
  @media (max-width: 720px) {
    display: none;
  }
}

.factDeposit {
  & > * {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    &:last-of-type {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media (max-width: 720px) {
    & > * {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.largeSpacing {
  margin-bottom: 8px;
}

.desktop {
  @media (max-width: 720px) {
    display: none;
  }
}

.button {
  padding: 9px 14px;
  font-size: 12px;
  font-weight: 700;
}

.mobile {
  margin-top: 24px;
  &:hover{
    background-color: #8e3fbe;
  }
  @media (min-width: 720px) {
    display: none;
  }
}

.wrapper {
  @media (max-width: 720px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.expandWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.summary {
  margin-left: 5px;
}

div.card {
  padding-bottom: 20px;
  @media (max-width: 720px) {
    padding: 24px 24px 0;
  }
}

.currentWeekLabel {
  margin-bottom: 4px;
  @media (max-width: 720px) {
    font-weight: 500;
    margin: 0;
  }
}

.nextWeekLabel {
  @media (max-width: 720px) {
    font-weight: 500;
    color: #535353;
  }
}

.onlyMobile {
  @media (min-width: 720px) {
    display: none;
  }
}