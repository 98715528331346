.container {
  background: #fff;
  width: 100%;
}

.img {
  margin: 8px 0;
  height: 32px;
}

.wrapper {
  margin: 8px;
  font-size: 12px;
  display: flex;
  justify-content: center;

}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1124px;
  margin: 0 auto;
}