.title {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #8a8a8a;
  @media (max-width: 1500px) {
    margin-top: 16px;
  }
}
