.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 1136px;
  @media (max-width: 1200px) {
    min-width: 100%;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  @media (max-width: 720px) {
    margin-bottom: 8px;
  }
}

.title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.content {
  display: flex;
  flex: 1;
  @media (max-width: 920px) {
    flex-direction: column;
  }
}

.contentItem {
  border-right: 1px solid #c1c1c1;
  margin-right: 24px;
  padding-right: 24px;
  display: flex;
  flex: 1;
  width: 330px;

  &:last-of-type {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
    flex-direction: column;
  }

  &:first-of-type {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }
  @media (max-width: 920px) {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    width: 100%;
    &:not(:last-of-type) {
      border-bottom: 1px solid #c1c1c1;
      padding-bottom: 8px;
      margin-bottom: 6px;
    }
  }
}

.guaranteedDiscountContainer {
  margin-bottom: 8px;
  margin-top: 16px;
  @media (max-width: 720px) {
    margin: 0;
    width: 100%;
  }
}

.historyPeriod {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-transform: capitalize;
  margin-bottom: 4px;
  @media (max-width: 720px) {
    margin: 0;
  }
}

.historyDate {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #333;
  text-transform: capitalize;
}

.discountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 720px) {
    margin-left: 32px;
    width: calc(100% - 32px);
    margin-top: 0;
  }
}

.historyContainer {
  width: 100%;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.discountText {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.discountPercent {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.needToBuyContainer,
.buyProductsContainer,
.guaranteedDiscountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.needToBuyContainer {
  margin-bottom: 16px;
}

.buyProductsContainer {
  margin-bottom: 2px;
  @media (max-width: 720px) {
    margin-bottom: 0;
  }
}

.guaranteedDiscountLabel {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #535353;
}

.guaranteedDiscountValue {
  color: #7c7c7c;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.buyProductsLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #535353;
  margin: 0;
}

.buyProductsValue {
  color: #535353;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.needToBuyLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: #000;
  margin: 0;
}

.needToBuyValue {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
  margin: 0;
}

.link {
  &:global(.MuiButtonBase-root){
    padding: 6px 8px;
  }
  @media (max-width: 920px) {
    display: none;
  }
}

.mobileLink {
  margin-top: 24px;
  & > button {
    width: 100%;
  }
  @media (min-width: 720px) {
    display: none;
  }
}

.historyContent {
  @media (max-width: 720px) {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-left: 32px;
    }
  }
}

div.summary {
  & > div {
    flex-direction: column;
  }
}

.count {
  & > * {
    font-weight: 400;
    color: #7c7c7c;
  }
}

div.card {
  @media (max-width: 720px) {
    padding: 24px;
  }
}
