.offersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 8px;
  @media (max-width: 720px) {
    padding-top: 10px;
  }
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 10px;

  &.hideContainer {
    display: none;
  }

  @media (max-width: 720px) {
    margin-bottom: 8px;
  }

  &.MuiFormControlLabel-root {
    height: 24px;

    & .MuiSwitch-root {
      height: 24px;
      padding: 7px;

      & > * {
        padding: 3px 0 5px 12px;
      }
    }
  }
}

.labelSwitch {
  margin-left: auto;
  margin-right: 0;
}

.icon {
  & > svg > path {
    stroke: #009bdd;
  }
}

.inCartLabel {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #009bdd;
}

.inCartCount {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #009bdd;
  margin-left: 4px;
}

.inCartContainer {
  display: flex;
  align-items: center;
}

div.summary {
  height: 24px;

  & > div:first-of-type {
    margin: 0;

    & > span {
      min-height: 24px;
    }
  }

  & > div:last-of-type {
    padding: 8px 6px;
  }
}

.details {
  padding: 0;
}

.expandPanel {
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;

  &:not(:first-of-type) {
    padding-top: 16px;
  }
}
