.container {
  margin: 72px 0 45px;
  @media (max-width: 1500px) {
    margin: 72px 0 17px;
  }
}

.heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #333;
}

.list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.item {
  margin-top: 24px;
  color: #666;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  &:first-of-type {
    margin-top: 32px;
  }
}