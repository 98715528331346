.toolbarContainer {
  width: 100%;
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;
  @media (min-width: 720px) {
    display: none;
  }
  &.dashboardAction {
    display: flex;
    height: 24px;
    margin-bottom: 24px;
    @media (max-width: 720px) {
      height: 32px;
      margin-bottom: 4px;
    }
  }
}

.button {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #000;
  padding: 0;
  &:active,
  &:focus {
    background-color: transparent;
  }
  width: 100%;
  justify-content: flex-start;
  height: 14px;
}

.selectedLabel {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 14px;
}

.arrowRotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.arrow {
  transition: transform 0.2s ease-in-out;
  min-width: 12px;
}

.formControl {
  width: 100%;
}

.label {
  font-weight: 400;
  color: #515151;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
}
