.wrapper {
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
  gap: 2px;
  flex-direction: column;
}

.additionalText {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #666;
}

a.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.container {
  display: flex;
  gap: 16px;
  align-items: center;
}