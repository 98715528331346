.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.additionalText {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #666;
}
.phone {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333;
}
