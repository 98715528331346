.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 4px 0;
  border-top: 1px solid #eaeaea;

  &:last-of-type {
    border-top: 1px solid #ccc;
    border-bottom: none;
    padding: 12px 0 0;

    .label {
      font-weight: 500;
    }
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #666;
}

.quantityContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.itemQtyContainer {
  padding: 4px 0;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 6px;
}