.dialogContainer {
  padding: 32px 64px;
  max-width: 500px;

  @media (max-width: 960px) {
    padding: 16px 32px;
  }

  @media (max-width: 720px) {
    padding: 8px 16px;
  }
}

.dialogTitle {
  padding-left: 0;
  padding-right: 0;
}

.dialogContent {
  overflow: visible;
  margin-top: 24px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.checkbox {
  margin-top: 48px;
  padding-left: 4px;
}

.selectRoot {
  margin-top: 24px;
}

.modalActions {
  justify-content: flex-end;
}
