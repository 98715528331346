.container {
  display: flex;
  flex-direction: column;

}

.title {
  width: 100%;
  padding-left: 48px;
  position: relative;
}

.titleIcon {
  position: absolute;
  left: 0;
  top: 0;
  color: #000;
}

.titleLoyalty {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
}

.subTitleLoyalty {
  margin-top: 0;
  margin-bottom: 24px;
}



.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: 720px) {
    gap: 24px;
  }
}