.container {
  max-width: 1600px;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 20px;
  display: flex;
  gap: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    gap: 100px;
    max-width: 1096px;
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 720px) {
    gap: 8px;
    padding-bottom: 50px;
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  width: 100%;
  margin-bottom: 0;
  @media (max-width: 720px) {
    margin-bottom: 8px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  @media (max-width: 720px) {
    gap: 0;
  }
}

.header {
  margin-bottom: 0;
  width: 100%;
}

.discountGroupsTitle {
  margin-bottom: 9px;
}

button.btn {
  padding: 10px 13px;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-align: center;
  @media (max-width: 720px) {
    display: none;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.discountRule {
  width: 100%;
}

.balanceHistoryWrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.balanceHistoryGrid {
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 16px;
    max-width: calc(100% - 16px);
  }
  @media (max-width: 1200px) {
    max-width: 100%;
  }
}

.balanceHistoryCards {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex: 0 0 40%;
    gap: 16px;
  }
  @media (max-width: 720px) {
    gap: 16px;
  }
}

.balanceHistoryCard {
  flex: 1;

  @media (min-width: 600px) {
    flex: 0 0 60%;
  }
}

.balanceHistoryWrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: row;
  & > * {
    flex: 1;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

.wrapperRules {
  max-width: 440px;
}

button.tab {
  padding: 13px 0;
  margin-right: 24px;
  &:last-of-type {
    margin-right: 0;
  }
}

div.outlineMargin {
  max-width: 1136px;
  @media (max-width: 720px) {
    margin-bottom: 0;
    box-shadow: 2px 2px 10px 0px #00000029;
    margin-right: -16px;
    margin-left: -16px;
    margin-top: 0;
  }
}

.tabContent {
  display: flex;
  flex: 1;
  width: 100%;
  gap: 20px;
  &.lastTabContent {
    max-width: 1136px;
    gap: 0;
    & > * {
      width: 100%;
    }
  }
  @media (max-width: 1920px) {
    flex-direction: column-reverse;
    max-width: 1136px;
  }
  @media (max-width: 720px) {
    gap: 24px;
  }
}

.ruleTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: #000;
  margin-bottom: 8px;
  &:last-of-type {
    margin-top: 40px;
  }
}

.tabs {
  @media (max-width: 720px) {
    background-color: #fff;
    margin-right: -16px;
    margin-left: -16px;
    padding: 0 16px;
    box-shadow: 2px 2px 10px 0px #00000029;
    margin-bottom: 24px;
  }
}

.maxDiscount {
  margin: 0;
}

.expandPanel {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;

  &:not(:first-of-type) {
    padding-top: 16px;
  }
}

.heading {
  & > a {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
  }
}