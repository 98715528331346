.titleContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
.subTitle {
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #535353;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}
