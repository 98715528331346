.divider {
  border: 1px solid #e4e4e4;
  margin: 0;
}

.img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
}

.productDetailsWrapper {
  display: flex;
  gap: 16px;
  max-width: 332px;
  flex: 1;
  align-items: center;
  &.accountProductDetailsWrapper {
    max-width: 416px;
    @media (max-width: 1500px) {
      max-width: 370px;
    }
  }
}

.container {
  display: flex;
  padding: 16px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  color: #1b1b1c;
  text-decoration: none;
  &:hover {
    cursor: default;
    color: #1b1b1c;
    text-decoration: none;
  }
}

.orderItemRemove {
  max-width: 56px;
  display: flex;
  align-items: center;
  flex: 1;
}

.paramValue {
  max-width: 90px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.orderItemSubtotal {
  max-width: 116px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.inPackageCount {
  max-width: 84px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  @media (max-width: 1160px) {
    display: none;
  }
  &.accountInPackageCount {
    max-width: 140px;
  }
}

.quantity {
  max-width: 208px;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  &.accountQuantity {
    max-width: 140px;
  }
}

.price {
  max-width: 90px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.splicedValue {
  & > * {
    font-weight: 700;
  }
}

.totalPrice {
  & > * {
    font-weight: 700;
  }
}

.discountAmount {
  & > * {
    font-weight: 400;
  }
}