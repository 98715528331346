.field {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #535353;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
    color: #000;
  }
}

.amountBase {
  line-height: 22px;
  & > * {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #535353;
    height: 22px;
    @media (max-width: 720px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  &.replenishment {
    & > * {
      color: #8f9e1c;
    }
  }
}

.balance {
  line-height: 22px;
  & > * {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #535353;
    height: 22px;
    @media (max-width: 720px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
    }
  }
}

.balanceContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.date {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-right: 8px;
}

.value {
  line-height: 22px;
}

.type {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  &:first-letter {
    text-transform: capitalize;
  }
}

span.link {
  & > a > span {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
