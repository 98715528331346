.filterButton {
  background-color: #8e3fbe;
  margin-right: 24px;
  &:global(.MuiButtonBase-root){
    padding: 8px 12px;
  }
  &:global(.MuiButtonBase-root):hover{
    background-color: #8e3fbe;
    cursor: pointer;
  }
  @media (min-width: 1550px) {
    display: none;
  }
}

.icon {
  background-color: transparent;
}

.label {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}