.tableToolbarContainer {
  margin: 0 0 16px;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
    height: 32px;
    display: flex;
    align-items: center;
  }
}

.button {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #000;
  padding: 0;
  height: 32px;
  &:active,
  &:focus {
    background-color: transparent;
  }
  @media (max-width: 720px) {
    width: 100%;
    justify-content: flex-start;
    height: 14px;
  }
}

.selectedLabel {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.arrowRotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.arrow {
  transition: transform 0.2s ease-in-out;
  min-width: 12px;
}

.formControl {
  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #515151;
  white-space: nowrap;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}
