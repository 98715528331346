.deliveryContainer {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 720px) {
    margin-bottom: 40px;
  }
}

.cardLoyaltyContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 24px 16px;
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.titleCommon {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.cardLoyalty {
  overflow: hidden;
}

.wrapper {
  width: 48%;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.cardDeliveryLabel {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
}

.orderCount {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
}

.cardLink {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.label {
  color: #535353;
}

.link {
  &:hover {
    text-decoration: none;
  }
}
