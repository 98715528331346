.headerNavGroup {
  &:first-of-type {
    margin: 0;
  }

  width: 280px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 960px) {
    padding-left: 32px;
    width: auto;
  }
  @media (max-width: 960px) {
    display: none;
  }
}

.headerGroup {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-right: 24px;
}

.iconWrapper {
  display: flex;
  @media (min-width: 960px) {
    display: none;
  }
}

.iconLink {
  padding: 9px;
  margin: 0 10px;
  min-width: 40px;
}

.iconActive {
  color: #8e3fbe;
}

.filtersEnabled {
  &::after {
    content: '';
    position: absolute;
    top: 4px;
    right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #009bdd;
  }

  @media (max-width: 720px) {
    &::after {
      top: 6px;
      right: 8px;
    }
  }
}

.mobileLayoutSelect {
  margin-right: 2px;
  font-size: 12px;
  color: #141414;
}

.mobileItem {
  min-width: 20px;
}

.infoGroup {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 26px;
  justify-content: flex-end;
  width: 436px;

  & > div {
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}

.actions {
  @media (max-width: 960px) {
    display: none;
  }
}

.headerBottom {
  padding: 0;
  display: flex;
  align-items: center;
}

.headerBottomDevice {
  padding: 0;

  .headerGroup {
    margin: 0;
    height: 40px;

    .iconWrapper {
      .iconLink {
        padding: 8px;
        margin: 0 0 0 8px;
        min-height: 40px;
        border: 1px solid #ccc;
        color: #141415;
      }
    }
  }

  @media (min-width: 720px) {
    margin: 0 32px;
  }
}

.headerBottomDeviceCatalog {
  padding: 4px 16px 8px;
  @media (max-width: 960px) {
    padding: 4px 0 8px;
  }
  @media (max-width: 720px) {
    padding: 4px 16px 8px;
  }
}

.mobileSortingSelect {
  display: none;
}

.mobileInputDropdownMenu {
  & :global(.MuiInputBase-input) {
    padding: 12px 0 12px 8px;
  }

  & fieldset {
    border: none;
    margin-bottom: 3px;
  }
}

.mobileMenuItem {
  padding: 9px 6px;
  margin-bottom: 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ebebeb;
  }

  &.Mui-selected {
    background-color: inherit;
  }

  .amount {
    font-weight: 500;
  }

  & :global(.main-label) {
    color: #ccc;
    font-size: 12px;
  }

  &:hover {
    background-color: #fff;

    .main-label {
      color: #000;
    }
  }

  &:active {
    background-color: #f1e8f7;
  }

  &:active + &:before,
  &.FlrSelect2-menuItemSelected + &:before {
    display: none;
  }
}

.inputDropdownMenuOpen {
  & :global(.MuiInputBase-input) {
    padding: 12px 0 12px 8px;
  }
}
