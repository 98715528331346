.leftSideItemAnchor {
  position: relative;
  display: block;
  padding: 7px 0 13px 16px;
  &::before {
    content: '';
    position: absolute;
    border-radius: 2px;
    width: 4px;
    left: 0;
    top: 10px;
    bottom: 10px;
    background: transparent;
    @media (max-width: 1500px) {
      top: 10px;
      bottom: 10px;
    }
  }
  &:hover {
    cursor: pointer;
    &:not(.active)::before {
      background: #1a1a1a;
    }
  }
  @media (max-width: 1500px) {
    padding: 4px 0 13px 16px;
  }
}

.active {
  & .warehouseName {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  &::before {
    background: #8e3fbe;
  }
  &:active {
    &::before {
      background: #f1e8f7;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0 6px;
}

.deliveryDate {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #000;
}