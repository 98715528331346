.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.link {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

div.card {
  padding: 24px;
  margin-bottom: 16px;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #535353;
}

.name {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #262626;
}

.percent {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #262626;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 7px;
  &:not(:last-of-type) {
    padding: 7px 0 5px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.list {
  margin-top: 16px;
}
