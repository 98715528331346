.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 24px 24px;
  @media (max-width: 720px) {
    padding: 0 0 20px;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebebeb;
  z-index: 10;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  @media (max-width: 720px) {
    position: fixed;
  }
}

.miniCartCardSummary {
  margin-top: 16px;
  @media (max-width: 1366px) {
    display: none;
  }
}

.cardTitle {
  color: #8e8e8e;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 22px 0 17px;
  user-select: none;
  @media (max-width: 1858px) {
    display: none;
  }
}
