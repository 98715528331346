.container {
  margin-bottom: 16px;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.cardTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-right: auto;
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

div.card {
  padding: 24px 32px;
}

.creditContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.balanceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &:not(:last-of-type) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.factBalanceLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #535353;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.factBalanceValue {
  & > * {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #535353;
  }
}

.button {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: normal;
  padding: 10px 15px;
}

.itemTotalBalance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    padding-bottom: 8px;
  }
  &:last-of-type {
    padding-top: 2px;
  }
}


span.splitedPriceItem {
  & > * {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    line-height: 23px;
  }
}

.balanceItemSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #666;
}