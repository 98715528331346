.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.deliveryWrapper {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.tableHeaders {
  display: flex;
  padding-bottom: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 16px;
  border-bottom: 1px solid #ccc;
  &:last-of-type {
    border-bottom: none;
    border-top: none;
  }
}

.orderHeader {
  display: flex;
  flex: 1;
  max-width: 120px;
}

.statusHeader {
  display: flex;
  flex: 1;
  max-width: 108px;
}

.dateHeader {
  display: flex;
  flex: 1;
  max-width: 120px;
}

.suplierHeader {
  display: flex;
  flex: 1;
  max-width: 120px;
}

.deliveryMethodHeader {
  display: flex;
  flex: 1;
  max-width: 283px;
}

.qtyHeader {
  display: flex;
  flex: 1;
  max-width: 104px;
  justify-content: flex-end;
}

.qtyContainer {
  display: flex;
  flex: 1;
  max-width: 104px;
  justify-content: flex-end;
  flex-direction: column;
}

.sumHeader {
  width: 84px;
  display: flex;
  justify-content: flex-end;
}

.sumContainer {
  width: 84px;
  display: flex;
  justify-content: flex-end;
}

.outletName {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}

.deliveryOrder {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #666;
}

.card {
  padding: 16px;
}

.outlineMargin {
  transform: translateY(-24px);
  margin-top: 24px;
  margin-left: -36px;
  margin-right: -32px;
}

.orderCode {
  max-width: 120px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.orderStatus {
  max-width: 108px;
  display: flex;
  flex: 1;
}

.deliveryDateContainer {
  max-width: 120px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.deliveryContainer {
  max-width: 283px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.warehouseContainer {
  max-width: 120px;
  display: flex;
  flex: 1;
}
