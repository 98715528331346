.deliveryContainer {
  display: flex;
  margin-bottom: 32px;
  gap: 18px;
  flex-wrap: wrap;
  &.returnOrder {
    display: none;
  }
}

.shippingMethodContainer {
  max-width: 204px;
  @media (max-width: 720px) {
    max-width: 100%;
    width: 100%;
  }
}

.shippingMethodDescription {
  margin-top: 8px;
}

.outletContainer {
  width: 100%;
  max-width: 232px;
  @media (max-width: 720px) {
    max-width: 100%;
  }
}

.outletDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timePeriodContainer {
  display: flex;
  justify-content: space-between;
  max-width: 316px;
  gap: 8px;
  @media (max-width: 720px) {
    max-width: 100%;
    gap: 68px;
  }
  &.timePeriodContainerSelfpickup {
    @media (max-width: 720px) {
      gap: 40px;
    }
  }
  &.timePeriodInner {
    @media (max-width: 720px) {
      & > * {
        flex: 1;
      }
      gap: 16px;
      width: 100%;
    }
  }
}

.timePeriodWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

div.timePeriodContent {
  min-width: 144px;

  & :global(.MuiInputBase-input.MuiOutlinedInput-input) {
    padding: 10px 0 10px 10px;
  }
  @media (max-width: 720px) {
    min-width: 96px;
  }
}

div.deliveryTime {
  & :global(.MuiInputBase-input.MuiOutlinedInput-input) {
    padding: 10px;
  }
}

.packageReturnContainer {
  min-width: 261px;
  max-width: 261px;
  @media (max-width: 1500px) {
    min-width: 120px;
  }
  @media (max-width: 720px) {
    min-width: 100%;
  }
}

.packageReturnDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 185px;
  & :global(.MuiButtonBase-root) {
    padding: 5px 5px 5px 8px;
  }
}

.datePicker {
  width: 100%;
}

.outletContent {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  justify-content: space-between;
}

.packageReturnContent {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  justify-content: space-between;
}

.deliveryPricePerCity {
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: #333;
}

.deliveryMethodValue {
  font-size: 14px;
}

.label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #535353;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    height: 16px;
  }
}

.value {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #262626;
  margin: 4px 0 10px;
  white-space: pre-wrap;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    margin: 4px 0 7px;
  }
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #535353;
}

.select {
  min-width: 204px;
  @media (max-width: 720px) {
    min-width: 100%;
  }
}
