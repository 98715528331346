.productDetails {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.container {
  padding: 0 16px;
  width: 100%;
}

.infoProduct {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
}

.card {
  width: 100%;
  margin-bottom: 8px;
}

.productAttr {
  width: 100%;
}

.img {
  width: calc(100% - 200px);
  @media (max-width: 720px) {
    width: calc(100% - 100px);
  }
}