.tab {
  display: flex;
  flex-direction: column;
  .tabContent {
    &:first-of-type {
      margin-top: 8px;
    }

    margin-top: 0;
  }
}

.cartLeftSideSubheader {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #8a8a8a;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 13px;
  margin-top: 16px;
  margin-right: 8px;
  @media (max-width: 1500px) {
    padding-top: 8px;
  }
}

