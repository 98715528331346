.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 100px);
  @media (max-width: 720px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.link {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

div.card {
  padding: 24px;
  margin-bottom: 16px;
}

.hr {
  border: 1px solid #c1c1c1;
  margin: 0 24px;
}

.item {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}

.nextDiscountLabel {
  color: #535353;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  margin: 0 0 16px;
}

.discountLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
  margin-right: auto;
}
.discountValue {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #262626;
}
