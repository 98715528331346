.catalogCard {
  height: fit-content;
  position: relative;

  &.selectedProduct {
    border: 2px solid #8e3fbe;
    grid-template-areas:
      "photo-container photo-container photo-container"
      "photo-container photo-container photo-container"
      "card-footer card-footer card-footer";

    .mainContainer {
      margin-top: 0;
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.gridContainer {
  display: grid;
  padding: 16px;
  grid-template-rows: 24px 160px 35px;
  grid-template-columns: 86px 1fr 1fr;
  grid-template-areas:
    "manufacturer-container manufacturer-container manufacturer-container"
    "photo-container main-container main-container"
    "card-footer card-footer card-footer";

  &.selectedProduct {
    grid-template-areas:
      "photo-container photo-container photo-container"
      "card-footer card-footer card-footer";
    grid-template-rows: 200px 48px;
    grid-template-columns: 86px 1fr 1fr;
    padding: 0;
  }
}

.manufacturerContainer {
  grid-area: manufacturer-container;

  &.selectedProduct {
    display: none;
  }
}

.flag {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;

  .specialSigns {
    margin-left: auto;
    display: flex;
    gap: 8px;

    .goodsComingBordered {
      border: 1px solid #ffb200;
      border-radius: 4px;
    }

    .fireBordered {
      border: 1px solid #d770ad;
      border-radius: 4px;
    }
  }
}

.manufacturerLabel {
  color: #666666;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.photoContainer {
  grid-area: photo-container;
  margin-top: 4px;
  margin-bottom: 8px;
  position: relative;

  &.selectedProduct {
    margin: 0;
  }

  .photo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-position: center;
    background-size: cover;

    &.selectedProduct {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-bottom: 8px;
  grid-area: main-container;

  &.selectedProduct {
    position: absolute;
    margin: 0;
    padding: 16px 16px 64px 16px;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

.mainContainer {
  grid-area: main-container;
  margin-top: 4px;
}

.mainType {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  &.selectedProduct {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #fff;
  }
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  margin-bottom: 4px;
  min-height: 38px;
  max-height: 38px;

  &.selectedProduct {
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    line-height: 29px;
    white-space: wrap;
    max-height: 58px;
    min-height: auto;
  }
}

.attributesContainer {
  grid-area: attributes-container;
  margin-top: auto;
}

.cardFooter {
  grid-area: card-footer;
  border-top: 1px solid #dcdcdc;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.selectedProduct {
    padding: 0 16px;
    border-top: 2px solid #8e3fbe;
  }
}

.height {
  min-width: 30%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-shrink: 0;
  align-items: center;

  > * {
    color: rgba(0, 0, 0, 0.8);
    margin-right: 8px;
  }
}

.favourite {
  position: absolute;
  top: 50px;
  left: 67px;
  right: 0;
  color: #fff;
  border-radius: 50%;
  z-index: 5;
  cursor: pointer;
  transition: none;
  padding: 5px 4px;
  width: fit-content;

  &:global(.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall) {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.selected {
    color: #e91e63;
    background: rgba(0, 0, 0, 0.2);
  }

  &.productSelected {
    color: #ffffff;
    top: 10px;
    right: 12px;
    margin-left: auto;

    &.selected {
      color: #e91e63;
    }
  }
}

.otherAttributesContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  gap: 6px;
  min-height: 44px;
}

.otherAttributesItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.otherAttributeValue {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
  margin: 0;
  color: #333;
}

.attribute {
  margin: 0;
}

.attributeContainer {
  display: flex;
  align-items: center;
  width: 50%;
  &.selectedAttr {
    width: auto;
  }
}

.color {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #333;
}

.price {
  display: flex;
  width: 100%;
  margin-top: 8px;
  justify-content: flex-end;

  &.selectedProduct {
    margin: 0;
  }
}

.discount {
  margin-right: auto;
  background-color: #cddc39;
  border-radius: 14px;

  > p {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #000;
    margin: 0;
    padding: 4px 9px;
  }
}

.preOrderText {
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-right: 8px;
  margin-top: 2px;
}

.splitedPrice {
  > * {
    color: #000;
    font-weight: 700;
  }

  &:last-of-type {
    font-size: 16px;
  }
}

.cardFooterPricePreOrder {
  .splitedPriceDiscount {
    & > * {
      font-weight: 700;
      color: #858585;
    }

    &:last-of-type {
      font-size: 16px;
    }
  }
}

.colorContainer {
  margin-left: 3px;
  margin-top: 0;
}

.minPriceLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
  margin-right: 4px;
}

.selectedAttributesContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  justify-content: flex-start;

  .attributeContainer {
    .icon {
      min-width: 24px;
      padding-left: 11px;
      &:first-of-type {
        border-left: 1px solid #fff;
        margin-left: 0;
      }

      & > svg > path {
        fill: #fff;
      }
    }

    .otherAttributeValue {
      color: #fff;
      margin-right: 8px;

      &:not(:last-of-type) {
        border-right: 1px solid #fff;
      }
    }
  }
}

.packageInfo {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ebebeb;
}

.colorCircle {
  margin-left: 2px;
  margin-right: 12px;
}

.boldText {
  font-weight: 700;
  font-size: 18px;
  color: #858585;
}

.inCart {
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 24px;
  margin-bottom: 16px;
}
