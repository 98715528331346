.row {
  &:hover {
    width: 100%;
    box-shadow: 2px 4px 20px 0 #dbdbdb;
  }
}

.rowWrapperSelected {
  background: #f0e8f6;
  position: relative;
}

.rowWrapperSelected::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 26px;
  right: 26px;
  height: 1px;
  background-color: #8e3fbe;
}

.rowContainer {
  margin: 0 26px;
  border-bottom: 1px solid #ebebeb;
  height: 100%;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  @media (max-width: 720px) {
    margin: 0 16px;
  }
}

.nameWrapper {
  min-width: 21.5%;
  white-space: nowrap;
  margin-right: 8px;
  position: relative;
  flex-basis: 21.5%;
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    flex-basis: 100%;
    height: 28px;
    align-items: flex-end;
    max-width: 100%;
    min-width: 87%;
  }
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  display: block;
  font-weight: 500;
  @media (max-width: 720px) {
    font-size: 15px;
    white-space: nowrap;
    padding-bottom: 3px;
    text-align: left;
    margin: 0;
  }
}

.packageInfo {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-right: 12px;
}

.firstAttrWrapper {
  min-width: 73px;
  margin-right: 12px;
  white-space: nowrap;
  flex-basis: 73px;
  display: flex;
  align-items: center;
  @media (max-width: 1940px) {
    max-width: 95px;
  }
  @media (max-width: 720px) {
    display: none;
  }
}

.containerAttributes {
  margin-bottom: 8px;
  @media (max-width: 720px) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    align-items: center;
  }
}

.firstAttrIcon {
  min-width: 12px;
}

.firstAttrLabel {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
}

.manufacturer {
  min-width: 14%;
  margin-right: 8px;
  position: relative;
  flex-basis: 15%;
  text-decoration: underline;
  color: #616161;
  display: flex;
  @media (max-width: 1940px) {
    max-width: 152px;
  }
  @media (max-width: 720px) {
    margin-right: 8px;
    font-size: 12px;
    display: none;
  }
}

.colorCircle {
  margin-bottom: 2px;
}

.attributesWrapper {
  flex-grow: 1;
  min-width: 17%;
  flex-basis: 22%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 2300px) {
    flex-basis: 17%;
  }
  @media (max-width: 2150px) {
    flex-basis: 18%;
  }
  @media (max-width: 1940px) {
    min-width: 196px;
  }
  @media (max-width: 720px) {
    display: none;
  }
}

.attributes {
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #616161;
  @media (max-width: 720px) {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
  }
}

.categoryWrapper {
  min-width: 11%;
  max-width: 17%;
  white-space: nowrap;
  flex-basis: 7%;
  flex-grow: 3;
  display: flex;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 1940px) {
    max-width: 120px;
    flex-basis: 10%;
  }
  @media (max-width: 1540px) {
    margin-right: 0;
  }
  @media (max-width: 720px) {
    display: none;
  }
}

.fireBorderedImg {
  border: 1px solid #d770ad;
  border-radius: 4px;
}

.mainImg {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 8px;
}

.price {
  min-width: 5%;
  white-space: nowrap;
  margin-right: 8px;
  flex-basis: 64px;
  display: flex;
  align-items: center;
  @media (max-width: 1500px) {
    min-width: 7%;
    flex-basis: 8%;
  }
  @media (max-width: 720px) {
    margin-right: 0;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-basis: auto;
  }
}

.priceItem {
  width: 70px;
  text-align: right;
  font-weight: 700;
  @media (max-width: 720px) {
    font-weight: 400;
    min-width: 80px;
  }
}

.priceItemPreOrder {
  color: rgba(0, 0, 0, 0.44);
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: fit-content;
  }
}

.discountBadgeWrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 720px) {
    margin-right: 0;
  }
}

.emptyDiscountBadge {
  min-width: 43px;
}

.discountBadge {
  width: 43px;
  padding: 2px 8px;
  background-color: #cddc39;
  color: #333;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 720px) {
    padding: 0 6px;
    width: fit-content;
  }
}

.preOrderText {
  @media (max-width: 720px) {
    font-size: 10px;
    font-weight: 400;
    margin-right: 4px;
  }
}

.discountText {
  font-weight: 700;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 12px;
  }
}

.discountBadgeHeader {
  width: 54px;
  @media (max-width: 960px) {
    display: none;
  }
}

.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: #616161;
  display: block;
}

.overFlowText {
  overflow: hidden;
}

.priceLabel {
  & > * {
    font-weight: 700;
    line-height: 1;
  }
}

.mainContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 4px 0;
  overflow: hidden;
  @media (max-width: 720px) {
    overflow-x: auto;
    padding: 6px 0;
    height: 100%;
  }
}

.tooltipMobileDiscountPopper {
  z-index: 1500;
}

.tooltipMobileDiscountTooltip {
  @media (max-width: 720px) {
    margin: 12px 0 7px;
  }
}

.tooltipMobileDiscountRoot {
  @media (max-width: 720px) {
    margin-left: 10px;
    margin-top: 3px;
  }
}

.iconPreOrder {
  color: #cddc39;
}

.preOrderTooltip {
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.preOrderTooltipDate {
  font-weight: 400;
  color: white;
}

.preOrderContainer {
  margin-bottom: 16px;
  margin-right: 4px;
}

.preOrderTooltipLabel {
  display: flex;
  align-items: center;
  min-width: 18px;
}

.iconContainer {
  margin-left: 2px;
  display: flex;
  align-items: center;
  min-width: 38px;
  gap: 2px;
}

.tableLayoutHeader {
  padding: 24px 26px 8px 26px;
  border-bottom: 1px solid #cccccc;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;

  @media (max-width: 720px) {
    padding: 16px 0 9px;
    align-items: flex-end;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}

.icon {
  min-width: 18px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 720px) {
    text-overflow: initial;
    overflow: visible;
  }

  &.labelPrice {
    text-align: end;
    width: 100%;
    @media (min-width: 2100px) {
      text-align: center;
    }
    @media (max-width: 2100px) {
      text-align: right;
    }
    @media (max-width: 1500px) {
      text-align: center;
    }
  }
}

.tooltipContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.inCartWrapper {
  min-width: 6.5%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: flex-end;
  @media (max-width: 720px) {
    display: none;
  }
}

.unitWrapper {
  min-width: 5.5%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: flex-end;
  @media (max-width: 720px) {
    display: none;
  }
}

.otherAttributeValue {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 0;
}

p.rightOffset {
  margin-right: 8px;
}

.priceBold {
  font-weight: 700;

  > * {
    font-weight: 700;
  }
}

.cartCountContainer {
  display: flex;
  align-items: center;
}

.inCartCountLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
}

.minPriceLabel {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;
  margin-right: 6px;
}

.attributeContainer {
  display: flex;
  align-items: center;
}

.otherAttributeValue {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
  margin: 0;
  color: #333;
}

.attrContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
}

.tilde {
  font-weight: 700;
}

.inCartContainer {
  position: absolute;
  top: 6px;
  z-index: 3;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: #1b1d1e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconInCart {
  & > svg > path {
    stroke: #fff;
  }
}

.avaliableWrapper {
  min-width: 5.5%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: flex-end;
  @media (max-width: 720px) {
    display: none;
  }
}

.value {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #616161;
  margin-right: 0;
}

.availabelValue {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  color: #000;
}

.stockOfferContainer {
  min-width: 80px;
  display: flex;
  align-items: center;
  color: #000;
}

.discountContainer {
  display: flex;
  align-items: center;
  min-width: 80px;
}