.catalogCard {
  height: fit-content;
  position: relative;

  .container {
    display: grid;
    grid-template-rows: 139px 44px 24px 26px 38px;
    grid-template-columns: 88px auto 1fr 1fr;
    grid-template-areas:
      'photo-container photo-container photo-container photo-container'
      'main-container main-container main-container main-container'
      'manufacturer-container manufacturer-container manufacturer-container manufacturer-container'
      'attributes-container attributes-container attributes-container attributes-container'
      'card-footer card-footer card-footer card-footer';
    &.unauthorized {
      grid-template-rows: 139px 44px 24px 26px;
      grid-template-areas:
    'photo-container photo-container photo-container photo-container'
    'main-container main-container main-container main-container'
    'manufacturer-container manufacturer-container manufacturer-container manufacturer-container'
    'attributes-container attributes-container attributes-container attributes-container';
    }

    .manufacturerContainer {
      border-top: 1px solid #ccc;
      margin: 0 8px 0 8px;
      grid-area: manufacturer-container;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flag {
        display: flex;
        margin-right: 4px;
      }

      .manufacturerLabel {
        font-size: 12px;
        color: #666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .photoContainer {
      grid-area: photo-container;

      .photo {
        width: 100%;
        height: 100%;
        background: #f2f2f2 center center no-repeat;
        border-radius: 8px 8px 0 0;
      }
    }

    .mainContainer {
      grid-area: main-container;

      .specialPriceIcon {
        position: absolute;
        top: 40px;
        right: 4px;
      }

      .inTransitIcon {
        position: absolute;
        top: 76px;
        right: 4px;

        &.transitIcon {
          top: 40px;
        }
      }

      .name {
        height: 40px;
        margin-bottom: 8px;
        overflow: hidden;
        font-size: 20px;
        font-weight: 500;
        color: #000000;

        .mainTitle {
          margin: 8px;
          text-align: left;
          display: block;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 15px;
          line-height: 15px;
        }
      }
    }

    .attributesContainer {
      grid-area: attributes-container;

      .attributes {
        border-top: 1px solid #ccc;
        margin: 0 8px;
        padding: 0 4px;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 2px;
        overflow: hidden;

        .attribute {
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          line-height: 14px;
        }
      }
    }

    .cardFooter {
      background: transparent;
      border-top: 1px solid #ccc;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-area: card-footer;
      margin: 0 8px;
      position: relative;

      & :global(.MuiTooltip-tooltip){
        width: 200px;
      }

      .discountBadge {
        background-color: #cddc39;
        color: #000000;
        border-radius: 14px;
        margin: 0;
        padding: 0;
        max-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 1;

        p {
          padding: 2px 6px;
          margin: 0;
          font-size: 12px;
          font-weight: 700;
        }

        h4 {
          font-size: 14px;
        }
      }

      .price {
        width: 100%;
        z-index: 0;

        &.discount {
          justify-content: space-between;
          margin-bottom: 5px;
        }

        &.preOrder {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          color: #8f8f8f;
        }

        .doublePriceContainer {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          > span > * {
            font-weight: 700;
          }

          > *:not(:last-child) {
            margin-right: 5px;
          }

          .doublePrice {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        .cardFooterPrice {
          width: calc(100% - 37px);
          justify-content: flex-end;
          flex-direction: row;
          align-items: flex-end;
          display: flex;
          position: relative;

          .preOrderText {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            margin: 0 4px 0 4px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            direction: rtl;
          }

          & > div {
            .preOrderPrice {
              > * {
                font-weight: 700;
                line-height: 19px;
              }

              &:last-of-type {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.icon {
  background-color: #fff;
  border-radius: 50%;
}

.priceFormat {
  margin: 0;
}

.noPriceMessage {
  font-size: 14px;
  text-align: right;
  margin-left: 10px;
}

.favourite {
  position: absolute;
  top: 5px;
  right: 3px;
  color: white;
  border-radius: 50%;
  z-index: 5;
  cursor: pointer;
  transition: none;

  &:global(.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall) {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.selected {
    color: #e91e63;
  }

  &.product-selected {
    color: #ffffff;

    &.selected {
      color: #e91e63;
    }
  }
}

.packageInfo {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #333;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
}

.boldText {
  font-weight: 700;
  font-size: 18px;
  color: #858585;
}

.otherAttributesItem {
  display: flex;
  width: auto;
  align-items: flex-end;

  .otherAttributeValue {
    width: fit-content;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
  }
}

.label {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;
}

.colorCircle {
  margin: 0;
}
