.dialogContent {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

.changePasswordControl {
  margin-bottom: 16px;
}

.dialogContainer {
  padding: 64px 96px;
}

.title {
  margin-bottom: 16px;
}