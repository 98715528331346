.container {
  max-width: 1136px;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    gap: 100px;
    max-width: 1096px;
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 720px) {
    gap: 8px;
    padding-bottom: 50px;
    width: 100%;
  }
}

.title {
  @media (max-width: 720px) {
    margin-bottom: 5px;
  }
}

.wrapper {
  display: flex;
  gap: 40px;
  @media (max-width: 720px) {
    gap: 4px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
}