.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: #000;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.titleCommon {
  margin: 0;
}

.subTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #333;
}

.paperCard {
  padding: 16px 24px 24px 26px;
}

.cardContent {
  display: flex;
}

.cardBalanceBody {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #666;
}

.balanceContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.balanceValue {
  line-height: 29px;
  & > * {
    color: #000;
  }
  &.negativeBalance {
    & > * {
      color: #c82361;
    }
  }
}

div.label {
  padding-bottom: 16px;
  margin-bottom: 17px;
}

button.btn {
  font-weight: 700;
}