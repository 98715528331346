.tableItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 8px;
}

.outlineMargin {
  transform: translateY(-24px);
  margin-top: 24px;
  margin-bottom: -8px;
}

.cartCardItemAnchor {
  display: block;
  &.active + a {
    border-top-color: transparent;
  }
  &.active {
    width: calc(100% + 41px);
    background: #fff;
    border: 1px solid #8e3fbe;
    border-radius: 8px;
    padding: 16px 20px;
    margin: -8px -21px -25px;
  }
}

.details {
  padding-bottom: 0;
}