.root {
  user-select: none;
  scrollbar-width: thin;
  scrollbar-color: #b2b2b2 transparent;
  padding: 40px 0 24px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #b2b2b2;
  }

  :global(.RSPBprogressBar) {
    height: 8px;
    background: #e6e6e6;
    :global(.RSPBstep) {
      transform: none;
    }
    :global(.RSPBstep) {
      &:not(:first-child):not(:nth-last-child(-n + 2)) {
        .value,
        .label,
        .point {
          transform: translateX(-50%);
        }
      }
      &:nth-last-child(-n + 2) {
        .point {
          transform: translateX(-50%);
        }
        .value,
        .label {
          transform: translateX(-100%);
        }
      }
    }
  }
  :global(.RSPBprogression) {
    height: 8px;
    background: #8e3fbe;
  }
}

.scrolledRoot {
  overflow-x: scroll;
}

.step {
  .label {
    width: fit-content;
    margin: 0;
    color: #7c7c7c;
  }
  .value {
    width: fit-content;
    top: 0;
    color: #7c7c7c;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  .point {
    width: 4px;
    height: 16px;
    border-radius: 8px;
    background: #e6e6e6;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
}

.active {
  .label {
    color: #8e3fbe;
  }
  .value {
    padding-bottom: 0;
    margin: 0;
    color: #8e3fbe;
  }
  .point {
    width: 4px;
    height: 16px;
    border-radius: 10px;
  }
}

.accomplished {
  .point {
    background: #8e3fbe;
  }
}
