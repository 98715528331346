.productImage {
  height: 40px;
  width: 40px;
  border-radius: 4px;
}

.container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  gap: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.productDetailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.qtyLabel {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #000;
  width: 35%;
}

.displayName {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: clamp(200px, 100vw - 150px, 300px);
  max-width: fit-content;
}

.link {
  max-width: fit-content;
}