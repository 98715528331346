.paginationRoot {
  margin-top: 16px;
  & > ul {
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 720px) {
      justify-content: center;
    }
  }
  @media (max-width: 720px) {
    margin-top: 20px;
  }
}

.item {
  color: #333;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &.itemSelected {
    color: #8e3fbe;
    background-color: #f1e8f7;
  }
}