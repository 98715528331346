.checkoutBreadcrumbs {
  margin: 16px;
  @media (max-width: 720px) {
    margin: 0;
    position: fixed;
    top: 54px;
    background-color: #f9f9f9;
    z-index: 2;
    padding: 14px;
  }
}