.wrapper{
  width: 100%;
  display: flex;
  align-items: center;
}

.inCartCount{
  margin-left: auto;
  color: #009BDD;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 0;
}

.inCartLabel{
  color: #009BDD;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 5px 0;
}

.icon {
  & > svg > path {
    stroke: #009BDD;
  }
}