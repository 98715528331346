.container {
  max-width: 1136px;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    gap: 100px;
    max-width: 1096px;
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 720px) {
    gap: 8px;
    padding-bottom: 50px;
    width: 100%;
  }
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 40px;
  button.changePasswordBtn {
    padding: 6px 24px;
    @media (max-width: 720px) {
      display: none;
    }
  }
}

.title {
  color: #000;
  margin-bottom: 0;
}

.settingsContainer {
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
  @media (max-width: 1350px) {
    flex-direction: column;
  }
}

.personalDataContainer {
  height: 100%;
  display: flex;
  flex: 1;
}

.paper {
  height: 100%;
  width: 100%;
  padding: 24px;
}

.personalDataWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.titleGroupContainer {
  margin-bottom: 8px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.titleGroup {
  margin: 0;
}

.btn {
  @media (max-width: 720px) {
    display: none;
  }
}

.link {
  @media (min-width: 720px) {
    display: none;
  }
}

.subTitleGroupCaption {
  color: #666;
}

.businessContainer {
  height: 100%;
  display: flex;
  flex: 1;
}

.businessWrapper {
  display: flex;
  flex-direction: column;
}
