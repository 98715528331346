.container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid #8e3fbe;
  border-top: none;
  width: calc(100% + 38px);
  margin-left: -19px;
  padding: 0 19px 0;
  border-radius: 0 0 8px 8px;
}

.expandedMobileRow {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
