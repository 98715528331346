.container {
  display: flex;
  max-width: 1136px;
  gap: 112px;
  margin-left: 52px;
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    margin-left: 16px;
    gap: 100px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  @media (max-width: 720px) {
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 720px) {
    gap: 56px;
    padding-bottom: 50px;
  }
}

.mobileLoyalty {
  @media (min-width: 720px) {
    display: none;
  }
}

.desktopLoyalty {
  @media (max-width: 720px) {
    display: none;
  }
}

.leftSide {
  display: flex;
  max-width: 560px;
  min-width: 560px;
  flex: 1;
  flex-direction: column;
  @media (max-width: 1500px) {
    max-width: 540px;
    min-width: 540px;
  }
  @media (max-width: 720px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}

.rightSide {
  display: flex;
  max-width: 464px;
  min-width: 464px;
  flex: 1;
  @media (max-width: 1500px) {
    max-width: 429px;
    min-width: 429px;
  }
  @media (max-width: 720px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}

.financesSection {
  width: 100%;
}

.financesCard {
  margin-bottom: 16px;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.creditLineCard {
  margin-bottom: 16px;
  padding: 24px 24px 32px;
  @media (max-width: 720px) {
    margin-bottom: 24px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: 0 0 16px;
  width: 100%;
  text-align: left;
  color: #000;
  padding: 0 15px 0 16px;
  @media (min-width: 720px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}