.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
}

.priceWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  user-select: none;
}

.priceContainer {
  display: flex;
  width: auto;
}

.price {
  display: flex;
  max-width: 64px;
  margin-right: 8px;
  flex: 1;
  @media (max-width: 720px) {
    display: block;
    max-width: initial;
    margin-right: 0;
  }
  line-height: 14px;
  & > * {
    font-weight: 400;
    color: #666;
    line-height: 12px;
  }
}

.quantityWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
}

.warehouseName {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  @media (max-width: 720px) {
    width: 100%;
  }
  &.warehouseNameActive {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    @media (max-width: 720px) {
      padding-bottom: 2px;
    }
  }
}

.warehouseContainer {
  width: 100%;
  padding-bottom: 8px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 720px) {
    display: block;
    border-bottom: 1px solid #ebebeb;
  }
  &.warehouseWrapper {
    @media (max-width: 720px) {
      padding-bottom: 10px;
    }
  }
}

.deliveryDate {
  font-size: 14px;
  line-height: 14px;
  color: #5c5c5c;
  font-weight: 500;
}

.deliveryLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #5c5c5c;
}

.inPackageCountLabel {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  user-select: none;
  min-width: 32px;
  display: flex;
  justify-content: flex-end;
  margin: 0 8px;
  @media (max-width: 720px) {
    margin-right: 4px;
    width: 100%;
  }
}

.button {
  margin: 8px !important;
}

.deliveryContainer {
  line-height: 14px;
  display: flex;
  align-items: center;
}

.labels {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.content {
  display: flex;
  align-items: center;
}

.desktopContainer {
  width: 100%;
}

.pricePerItemLabel {
  min-width: 64px;
  max-width: 64px;
  display: flex;
  flex: 1;
  margin-right: 8px;
}

.qtyInItemsLabel {
  min-width: 206px;
  max-width: 206px;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 8px;
}

.qtyInPackageLabel {
  min-width: 32px;
  max-width: 32px;
  display: flex;
  flex: 1;
  margin-right: 8px;
  justify-content: flex-end;
}

.trash {
  min-width: 24px;
  max-width: 24px;
  flex: 1;
}

.trashIcon {
  min-width: 40px;
}

.qtyContainer {
  width: 122px;
}

.autoCompleteContainer {
  &:global( .MuiFormControl-root){
    width: 100%;
  }
}