.headerWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: column;
}

.orderCard {
  width: 100%;
  &:not(:first-of-type) {
    display: none;
  }
}

.orderCardActual {
  &:last-of-type {
    margin-bottom: 0;
  }
}

div.outlineMargin {
  transform: translateY(-24px);
  margin-top: 24px;
  margin-left: -20px;
  margin-right: -16px;
  margin-bottom: -8px;
}

.deliveryOutlet {
  color: #000;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.deliveryOrder {
  color: #8e8e8e;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
