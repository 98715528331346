.miniCartCardItemAnchor {
  display: block;
  width: 100%;
  @media (min-width: 720px) {
    cursor: pointer;
  }
  &:not(.active):hover {
    @media (min-width: 720px) {
      text-decoration: none;
      cursor: pointer;
      & .rightSideItem {
        padding-right: 40px;
        & .editBtn {
          width: 32px;
          right: 8px;
          top: 10px;
        }
      }
    }
  }
  &.active {
    margin: 0 -4px;
    width: calc(100% + 8px);
    padding: 0 20px;
    background-color: #fff;
    border: 1px solid #8e3fbe;
    border-radius: 8px;
    & .itemRow {
      padding: 14px 0 17px;
    }
    @media (max-width: 720px) {
      margin: 0 -4px;
      width: calc(100% + 8px);
      padding: 0 16px;
      & .itemRow {
        padding: 16px 0 12px;
      }
    }

  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  flex: 1;
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding-top: 16px;
  @media (max-width: 720px) {
    border-top: none;
    padding-top: 0;
  }
}

.miniCartCardItemEdit {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 5px 6px 16px 7px;
  @media (max-width: 720px) {
    padding: 16px 0;
  }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  &.editContainer {
    flex-direction: column;
  }
}

.itemRow {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 7px 16px;
  overflow: hidden;
  &:first-of-type {
    border-top: 1px solid #ebebeb;
  }
  @media (max-width: 720px) {
    padding: 11px 0;
    align-items: flex-start;
    &:active {
      padding: 16px 0 11px;
    }
  }
}

.miniCartCardItemEditBtn {
  transition: all 0.5s ease-in-out;
  position: absolute;
  right: -32px;
  width: 0;
  top: 0;
  bottom: 0;
  @media (max-width: 720px) {
    display: none;
  }
}

.collapseItem {
  @media (min-width: 720px) {
    display: none;
  }
}

.fullName {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  max-width: 75%;
  @media (max-width: 720px) {
    max-width: 65%;
  }
}

.quantityItems {
  text-align: right;
  max-width: max-content;
  user-select: none;
  & > * {
    color: #666;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }
}

.miniCartCardItemTexts {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.itemInfo {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.sumPrice {
  display: flex;
  text-align: center;
  max-width: max-content;
  align-items: baseline;
  user-select: none;
  & > * {
    color: #666;
    @media (max-width: 720px) {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }
  .sumPriceActive {
    & > * {
      color: #000;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cancelBtn {
  &:global(.MuiButtonBase-root) {
    @media (max-width: 720px) {
      padding: 13px 0;
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
    }
  }
}

.applyBtn {
  &:global(.MuiButtonBase-root) {
    @media (max-width: 720px) {
      padding: 11px 24px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
    }
  }
}

.inPackageCount {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
}

.inPackageCountContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 83px;
  margin-right: 8px;
}
