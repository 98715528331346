.checkoutMain {
  min-width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f9f9f9;
  @media (max-width: 1160px) {
    min-width: 100%;
    padding: 106px 16px 60px;
  }
}

.checkoutContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.checkoutContent {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.deliveryContainer {
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.deliveryDate {
  color: #353535;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.preOrderDate {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.icon {
  & > svg > path {
    color: #8a8a8a;
  }
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
}

.warehouse {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #333;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.mobileTotalLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
}

.sumPrice {
  margin-left: 8px;
  & > * {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
  }
}

.mobileFooterContent {
  display: flex;
  align-items: center;
}

button.checkoutOrderFooterBlock {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-align: center;
  &:global(.MuiButtonBase-root){
    height: auto;
    padding: 13px 24px;
  }
}