.container {
  display: flex;
  flex: 1;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #666;
  text-align: left;
  &:active {
    color: #000;
  }
  &:hover {
    color: #8e3fbe;
  }
}
.logoutLeftBar {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 32px 0 0 24px;
  background: none;
  border: none;
  border-radius: 0;
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;
  color: #666;
  text-transform: none;
  justify-content: flex-start;

  &:focus {
    outline: none;
  }
  &:hover {
    color: #8e3fbe;
    background-color: transparent;
  }
  &:active {
    color: #000;
  }
}

.logoutLeftBarWrapper {
  width: 100%;
  display: inherit;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #666;
}

.title {
  margin: 0;
  margin-left: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #666;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.link {
  padding: 8px 0 8px 16px;
  min-height: auto;
}

a.tab {
  padding: 8px 0 8px 16px;
  min-height: auto;
}