.container {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0a0a0b;
  line-height: 18px;

  &.device {
    height: 54px;
    padding: 0 16px;
    top: 0;
    width: 100%;

    @media (min-width: 720px) {
      padding: 0 32px;
    }
  }
}

.cart {
  position: relative;
  z-index: 3;
}

.headerLogo {
  display: flex;
  flex: 1;
  justify-content: center;

  @media (max-width: 720px) {
    justify-content: flex-start;
  }
}

.icon {
  margin-left: 15px;
  & > svg > path {
    stroke: #666;
    stroke-width: 2px;
  }
  &-selected {
    & > svg > path {
      stroke: #2a0c5b;
    }
  }
}

.headerMobilePhoneContainer {
  flex: 1;
  justify-content: space-between;

  @media (min-width: 720px) {
    display: none;
  }
}

.headerMobilePhone {
  margin-right: 21px;
  width: 24px;
  height: 24px;

  @media (min-width: 720px) {
    display: none;
  }
}

.headerRightBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  @media (max-width: 960px) {
    justify-content: space-between;
  }
}

.rightBarMobile {
  display: flex;
  align-items: center;
}

.rightBar {
  flex-wrap: nowrap;
  & > * {
    padding-left: 0;
  }
}

.headerInfo {
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 960px) {
    display: none;
  }
}

.authWrapper {
  display: flex;
}

.buttonReg {
  margin-right: 8px;
}

.btnRegistration {
  @media (min-width: 1366px) {
    display: none;
  }
}

.phoneLink {
  margin-left: 8px;

  & > button {
    padding: 5px 8px;
    border: none;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.headerSummary {
  display: flex;
  align-items: center;
  width: min-content;
  margin: 0;
  @media (min-width: 960px) {
    display: none;
  }
  @media (max-width: 720px) {
    display: none;
  }
}

.rightBarContainer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.spacer {
  border-left: 1px solid #ccc;
  padding-left: 24px;
  height: 32px;

  @media (max-width: 960px) {
    display: none;
  }
}

.headerTopSettings {
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    display: none;
  }
  &.hideHeaderTopSettings {
    @media (max-width: 960px) {
      display: none;
    }
  }
}

.rightBarActions {
  flex-wrap: nowrap;
  align-items: center;
  flex: 1;
  display: flex;
}

.logo {
  @media (max-width: 720px) {
    display: none;
  }
}

.iconLogo {
  width: 208px;
  height: 24px;

  @media (max-width: 720px) {
    width: 120px;
  }
}
