@mixin text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.splicedPrice {
  & > * {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    &:last-of-type {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.displayName {
  color: #000;
  height: 16px;
  letter-spacing: normal;
  max-width: 90px;
  @include text-overflow-ellipsis;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mobilePrice {
  line-height: 14px;
  height: 16px;
  & > * {
    line-height: 14px;
    color: #000;
  }
}

.text {
  height: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 40px;
}

.orderIdWrapper {
  width: 136px;
  @media (max-width: 720px) {
    max-width: 92px;
  }
}

.typeWrapper {
  width: 162px;
  letter-spacing: normal;
  @media (max-width: 1100px) {
    width: 100px;
  }
}

.statusWrapper {
  width: 152px;
  display: flex;
  align-items: center;
  @media (max-width: 1500px) {
    width: 110px;
  }
  @media (max-width: 1100px) {
    width: 90px;
  }
}

.deliveryDateWrapper {
  width: 128px;
  text-transform: capitalize;
  & > * {
    letter-spacing: normal;
  }
  @media (max-width: 1100px) {
    width: 80px;
  }
}

.deliveryMethodWrapper {
  width: 215px;
  @media (max-width: 1500px) {
    width: 150px;
  }
  @media (max-width: 1100px) {
    width: 80px;
  }
}

.totalQtyWrapper {
  width: 96px;
}

.newOrderStatus {
  background-color: #f1e8f7;
  padding: 2px 8px;
  border-radius: 4px;
  line-height: 22px;
  letter-spacing: normal;
  @media (max-width: 720px) {
    transform: rotate(270deg);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 2px 4px;
  }
}

.totalSumWrapper {
  width: 150px;
  @media (max-width: 1100px) {
    width: 80px;
  }
}

.deliveryMethod {
  width: 189px;
  @include text-overflow-ellipsis;
}

.colorCircle {
  height: 8px;
  width: 8px;
  margin-right: 0;
}

.statusContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 720px) {
    align-items: flex-start;
  }
}

.createdAt {
  margin-top: 3px;
  @media (max-width: 720px) {
    @include text-overflow-ellipsis;
  }
}

.orderStatus {
  letter-spacing: normal;
}

.orderId {
  :global(.MuiTypography-root) {
    @media (max-width: 720px) {
      @include text-overflow-ellipsis;
      letter-spacing: normal;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      max-width: 84px;
      padding-bottom: 2px;
    }
  }
}
