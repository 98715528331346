.orderCardItemHeaders {
  display: flex;
  width: 100%;
  padding-bottom: 12px;
  @media (max-width: 960px) {
    display: none;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #666;
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.name {
  max-width: 332px;
  &.accountName {
    max-width: 416px;
  }
}

.price {
  max-width: 90px;
  display: flex;
  justify-content: flex-end;
}

.quantity {
  max-width: 208px;
  display: flex;
  justify-content: center;
  &.accountQuantity {
    max-width: 140px;
  }
}

p.discount {
  max-width: 90px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1160px) {
    max-width: 250px;
  }
  @media (max-width: 1100px) {
    max-width: 190px;
  }
  @media (max-width: 1050px) {
    max-width: 140px;
  }
  @media (max-width: 1000px) {
    max-width: 90px;
  }
}

p.inPackageCount {
  max-width: 84px;
  @media (max-width: 1160px) {
    display: none;
  }
  &.accountInPackageCount {
    max-width: 140px;
    justify-content: flex-end;
  }
}

p.removeItem {
  max-width: 56px;
  @media (max-width: 1160px) {
    display: none;
  }
  &.withoutRemoveItem {
    display: none;
  }
}

.sum {
  max-width: 116px;
  display: flex;
  justify-content: flex-end;
}
